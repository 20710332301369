import React from 'react';
import styles from '../assets/styles/_card.module.scss'

export default function Card({title, amount}) {
  return (
    <div className={styles.container_card}>
      <div className={styles.card}>
        <div className={styles.title}>{title}</div>
        <div className={styles.amount}>
          <p>Total:</p>
          <h4>{amount}</h4>
        </div>
      </div>
    </div>
  );
}
