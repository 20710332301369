import React, {Suspense} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {TOKE_NAME} from '../config';
import DashboardPage from './DashboardPage';
import Navbar from '../components/Navbar';
import '../assets/styles/_home_page.scss';
import { ColorRing } from 'react-loader-spinner';

const UsersPage = React.lazy(() => import('./UsersPage'));
const UserProfilePage = React.lazy(() => import('./UserProfilePage'));
const NeuroFrequenciesPage = React.lazy(() => import('./NeuroFrequenciesPage'));
const NewNeuroFrequencyPage = React.lazy(() => import('./NewNeuroFrequencyPage'));
const EditNeuroFrequencyPage = React.lazy(() => import('./EditNeuroFrequencyPage'));
const PaymentHistoryPage = React.lazy(() => import('./PaymentHistoryPage'));
const StatsPage = React.lazy(() => import('./StatsPage'));
const IncomePage = React.lazy(() => import('./IncomePage'));
const PackagePage = React.lazy(() => import('./PackagesPage'));

export default function HomePage(props) {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  return (
    <Router>
      <div className="page">
        <Navbar onExpiredToken={() => handleLogout(props, history)}/>
        <div className="container">
          <div className="sidebar">
            <NavLink to={`${url}/dashboard`} className="item" activeClassName="active">
              <img src={require('../assets/images/dashboard.png')} alt="Dashboard" className="icon" />
              Dashboard
            </NavLink>
            <NavLink to={`${url}/users`} className="item" activeClassName="active">
              <img src={require('../assets/images/usuarios.png')} alt="Usuarios" className="icon" />
              Usuarios
            </NavLink>
            <NavLink to={`${url}/neuro-frequencies`} className="item" activeClassName="active">
              <img src={require('../assets/images/neurofrequencia.png')} alt="Neuro Frequencias" className="icon" />
              Neuro Frequencias
            </NavLink>
            <NavLink to={`${url}/packages`} className="item" activeClassName="active">
              <img src={require('../assets/images/neurofrequencia.png')} alt="Paquetes" className="icon" />
              Paquetes
            </NavLink>
            <NavLink to={`${url}/stats`} className="item" activeClassName="active">
              <img src={require('../assets/images/dashboard.png')} alt="Estadisticas" className="icon" />
              Estadisticas
            </NavLink>
            <NavLink to={`${url}/method-payment`} className="item" activeClassName="active">
              <img src={require('../assets/images/tarjetas.png')} alt="Métodos de pago" className="icon" />
              Métodos de pago
            </NavLink>
            <NavLink to={`${url}/income`} className="item" activeClassName="active">
              <img src={require('../assets/images/ingresos.png')} alt="Ingresos" className="icon" />
              Ingresos
            </NavLink>
            <a href="/" onClick={event => {
              event.preventDefault();
              handleLogout(props, history);
            }} className="item bottom">
              <img src={require('../assets/images/salir.png')} alt="Cerrar sesión" className="icon" />
              Cerrar sesión
            </a>
          </div>
          <div className="content">
            <Suspense fallback={
              <div className="spinner-container">
              <ColorRing
                colors={['#001A5A', '#001A5A', '#001A5A','#001A5A', '#001A5A']}
              />
              </div>
            }>
              <Switch>
                <Route path={`${path}/dashboard`} component={DashboardPage}/>
                <Route path={`${path}/users`} exact component={UsersPage}/>
                <Route path={`${path}/users/profile/:id`} component={UserProfilePage}/>
                <Route path={`${path}/neuro-frequencies`} exact component={NeuroFrequenciesPage}/>
                <Route path={`${path}/neuro-frequencies/add`} component={NewNeuroFrequencyPage}/>
                <Route path={`${path}/neuro-frequencies/:id`} component={EditNeuroFrequencyPage}/>
                <Route path={`${path}/method-payment`} exact component={PaymentHistoryPage}/>
                <Route path={`${path}/stats`} exact component={StatsPage}/>
                <Route path={`${path}/income`} exact component={IncomePage}/>
                <Route path={`${path}/packages`} exact component={PackagePage}/>
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </Router>
  );
}

function handleLogout({ onLogout }, history) {
  localStorage.removeItem(TOKE_NAME);
  onLogout(() => history.push('/'));
}
