import React from 'react';
import {useHistory, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {loginUser} from '../store/actions';
import styles from '../assets/styles/_login_page.module.scss';

function LoginPage(props) {
  const {onLogin, data} = props;
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  console.log(data);
  if (typeof data.data === 'object' && data.data.token) {
    onLogin(() => {});
    return (
      <Redirect
        to={{
          pathname: '/app/dashboard',
          state: { from: history.location }
        }}
      />
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.login}>
        <h4 className={styles.title}>Admin Neuro Frequencies</h4>
        <p className={styles.subtitle}>Bienvenido, Inicia sesión para continuar</p>
        {data.error && <p className={styles.error}>{data.message}</p>}
        <form onSubmit={event => {
          event.preventDefault();
          handleLogin(props, {email, password});
        }}>
          <input type="text" placeholder="Correo electrónico" value={email} onChange={({target}) => setEmail(target.value)} />
          <input type="password" placeholder="Contraseña" value={password} onChange={({target}) => setPassword(target.value)} />
          {/* <p className={styles.forgotPassword}>¿Olvidaste tu contraseña?</p> */}
          {/* <p className={styles.forgotPassword}><Link to='signup'>Registrarse</Link></p> */}
          <button type="submit" disabled={data.isFeching}>INICIAR SESIÓN</button>
        </form>
      </div>
    </div>
  );
}

function handleLogin({login}, data) {
  login(data);
}

const mapStateToProps = state => {
  return {
    data: state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: data => {
      dispatch(loginUser(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
