import {
  SET_AUTH_TOKEN,
  FETCHING_DATA,
  SENDING_DATA,
  FETCHING_DATA_SUCCESS,
  FETCHING_DATA_FAILURE,
  SENDING_DATA_SUCCESS,
} from '../actionType';

import {
  login,
  signup,
  listUsers,
  listAdmins,
  getUserById,
  listNeuroFrequencies,
  newNeuroFrequency,
  removeNeuroFrequency,
  getAllPackages,
  savePackage,
  removePackage,
  dashboard,
  payments,
  income,
  stats, userStats, getNeuroFrequency, editNeuroFrequency, userAppliedNeuro
} from '../api';
import { TOKE_NAME } from '../../config';

export const getData = (key, value) => {
  return {type: FETCHING_DATA, key, value};
};

export const setToken = token => {
  return {type: SET_AUTH_TOKEN, token};
};

export const sendData = () => {
  return {type: SENDING_DATA};
};

export const getDataSuccess = (data, key) => {
  return {type: FETCHING_DATA_SUCCESS, data, key};
};

export const sendDataSuccess = message => {
  return {type: SENDING_DATA_SUCCESS, message};
};

export const getDataFailure = (message, key) => {
  return {type: FETCHING_DATA_FAILURE, message, key};
};

export const responseFailed = (dispatch, {response, message}) => {
  console.log(message, response);
  if (response && response.data.messages) {
    dispatch(getDataFailure(response.data.messages));
  } else if (message) {
    dispatch(getDataFailure(message));
  } else {
    dispatch(getDataFailure('Ocurrio un error intenta mas tarde'));
  }
}

export const loadToken = () => {
  return async dispatch => {
    const token = localStorage.getItem(TOKE_NAME);
    dispatch(setToken(token));
  }
};

export const loginUser = data => {
  return async dispatch => {
    dispatch(getData());
    try {
      const {data: json} = await login(data);
      if (json.token) {
        localStorage.setItem(TOKE_NAME, json.token);
        dispatch(getDataSuccess(json));
        dispatch(setToken(json.token));
      } else {
        throw new Error(json.message);
      }
    } catch ({message, response}) {
      if (response.data.messages || message) {
        dispatch(getDataFailure(response.data.messages || message));
      } else {
        dispatch(getDataFailure('Ocurrio un error intenta mas tarde'));
      }
    }
  };
};

export const signupUser = data => {
  return async dispatch => {
    dispatch(getData());
    try {
      const {data: json} = await signup(data);
      if (json.token) {
        localStorage.setItem(TOKE_NAME, json.token);
        dispatch(getDataSuccess(json));
        dispatch(setToken(json.token));
      } else {
        throw new Error(json.message);
      }
    } catch ({message, response}) {
      if (response.data.messages || message) {
        dispatch(getDataFailure(response.data.messages || message));
      } else {
        dispatch(getDataFailure('Ocurrio un error intenta mas tarde'));
      }
    }
  };
};

export const fetchUsers = params => {
  return async dispatch => {
    dispatch(getData('users', []));
    try {
      const {data: json} = await listUsers(params);
      dispatch(getDataSuccess(json, 'users'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchAdmins = params => {
  return async dispatch => {
    dispatch(getData('admins', []));
    try {
      const {data: json} = await listAdmins(params);
      dispatch(getDataSuccess(json, 'admins'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchUser = id => {
  return async dispatch => {
    dispatch(getData('selectedUser', {}));
    try {
      const {data: json} = await getUserById(id);
      dispatch(getDataSuccess(json, 'selectedUser'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchNeurofrequency = id => {
  return async dispatch => {
    dispatch(getData('selectedNeuro', {}));
    try {
      const {data: json} = await getNeuroFrequency(id);
      dispatch(getDataSuccess(json, 'selectedNeuro'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchNeurofrequencyApplied = id => {
  return async dispatch => {
    dispatch(getData('appliedNeuro', []));
    try {
      const {data: json} = await userAppliedNeuro(id);
      dispatch(getDataSuccess(json, 'appliedNeuro'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchNeurofrequencies = () => {
  return async dispatch => {
    dispatch(getData('neurofrequencies', []));
    dispatch(getData('updatedNeuro', {}));
    dispatch(getData('selectedNeuro', {}));
    try {
      const {data: json} = await listNeuroFrequencies();
      dispatch(getDataSuccess(json, 'neurofrequencies'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const createNeurofrequency = data => {
  return async dispatch => {
    dispatch(getData());
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (Array.isArray(value)) {
          value.forEach(item => formData.append(`${key}[]`, item));
        } else {
          formData.append(key, value);
        }
      });
      const {data: json} = await newNeuroFrequency(formData);
      dispatch(getDataSuccess(json));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const updateNeurofrequency = (id, data) => {
  return async dispatch => {
    dispatch(getData('updatedNeuro', {}));
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (Array.isArray(value)) {
          value.forEach(item => formData.append(`${key}[]`, item));
        } else {
          formData.append(key, value);
        }
      });
      const {data: json} = await editNeuroFrequency(id, formData);
      dispatch(getDataSuccess(json, 'updatedNeuro'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const destroyNeurofrequency = id => {
  return async dispatch => {
    dispatch(getData());
    try {
      await removeNeuroFrequency(id);
      dispatch(fetchNeurofrequencies());
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchPackages = () => {
  return async dispatch => {
    dispatch(getData('packages', []));
    try {
      const {data: json} = await getAllPackages();
      dispatch(getDataSuccess(json, 'packages'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const storePackage = data => {
  return async dispatch => {
    dispatch(getData());
    try {
      await savePackage(data);
      dispatch(fetchPackages());
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const destroyPackage = id => {
  return async dispatch => {
    dispatch(getData());
    try {
      await removePackage(id);
      dispatch(fetchPackages());
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const getDashboardInfo = () => {
  return async dispatch => {
    dispatch(getData('dashboard', {}));
    try {
      const {data: json} = await dashboard();
      dispatch(getDataSuccess(json, 'dashboard'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const getPayments = () => {
  return async dispatch => {
    dispatch(getData('payments', []));
    try {
      const {data: json} = await payments();
      dispatch(getDataSuccess(json, 'payments'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchIncomesData = params => {
  return async dispatch => {
    dispatch(getData('incomes', []));
    try {
      const {data: json} = await income(params);
      dispatch(getDataSuccess(json, 'incomes'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchMoreUseNeuro = params => {
  return async dispatch => {
    dispatch(getData('statsUseNeuro', []));
    try {
      const {data: json} = await stats('use-neuro', params);
      dispatch(getDataSuccess(json, 'statsUseNeuro'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchUserStats = params => {
  return async dispatch => {
    dispatch(getData('statUsers', []));
    try {
      const {data: json} = await stats('users', params);
      dispatch(getDataSuccess(json, 'statUsers'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchCountries = params => {
  return async dispatch => {
    dispatch(getData('statCountries', []));
    try {
      const {data: json} = await stats('countries', params);
      dispatch(getDataSuccess(json, 'statCountries'));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};

export const fetchUserStatsByType = (type, params) => {
  return async dispatch => {
    dispatch(getData(`userStats${type}`, []));
    try {
      const {data: json} = await userStats(type, params);
      dispatch(getDataSuccess(json, `userStats${type}`));
    } catch (error) {
      responseFailed(dispatch, error);
    }
  };
};
