import axios from 'axios';
import {API_URL, TOKE_NAME} from '../config';

function getHeader() {
    const token = localStorage.getItem(TOKE_NAME);
    return {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
}

function login(data) {
    return axios.post(`${API_URL}/login`, data);
    // return axios.post(`${API_URL}/login/admin`, data);
}

function signup(data) {
    return axios.post(`${API_URL}/signup`, data);
    // return axios.post(`${API_URL}/signup/admin`, data);
}

function listUsers(params) {
    return axios.get(`${API_URL}/users`, { ...getHeader(), params });
}

function listAdmins(params) {
    return axios.get(`${API_URL}/admins`, { ...getHeader(), params });
}

function getUserById(id) {
    return axios.get(`${API_URL}/users/${id}`, { ...getHeader() });
}

function listNeuroFrequencies() {
    return axios.get(`${API_URL}/neurofrequencies/all`, { ...getHeader() });
}

function getNeuroFrequency(id) {
    return axios.get(`${API_URL}/neurofrequencies/${id}`, { ...getHeader() });
}

function newNeuroFrequency(data) {
    console.log(data)
    return axios.post(`${API_URL}/neurofrequencies`, data, {
        ...getHeader(),
        'Content-Type': 'multipart/form-data',
    });
}

function editNeuroFrequency(id, data) {
    console.log(data, data.values())
    return axios.put(`${API_URL}/neurofrequencies/${id}`, data, { ...getHeader() });
}

function removeNeuroFrequency(id) {
    return axios.delete(`${API_URL}/neurofrequencies/${id}`, { ...getHeader() });
}

function getAllPackages() {
    return axios.get(`${API_URL}/packages`, { ...getHeader() });
}

function savePackage(data) {
    return axios.post(`${API_URL}/packages`, data, { ...getHeader() });
}

function removePackage(id) {
    return axios.delete(`${API_URL}/packages/${id}`, { ...getHeader() })
}

function dashboard() {
    return axios.get(`${API_URL}/home/dashboard`, { ...getHeader() });
}

function payments() {
    return axios.get(`${API_URL}/payment`, { ...getHeader() });
}

function income(params) {
    return axios.get(`${API_URL}/home/income`, { params, ...getHeader() });
}

function stats(type, params = {}) {
    return axios.get(`${API_URL}/home/statistics/${type}`, { params, ...getHeader() });
}

function userStats(type, params = {}) {
    return axios.get(`${API_URL}/home/user-statistics/${type}`, { params, ...getHeader() });
}

function userAppliedNeuro(id) {
    return axios.get(`${API_URL}/neurofrequencies/applied-user/${id}`, { ...getHeader() });
}


export {
    login,
    signup,
    dashboard,
    listUsers,
    listAdmins,
    getUserById,
    listNeuroFrequencies,
    getNeuroFrequency,
    newNeuroFrequency,
    editNeuroFrequency,
    removeNeuroFrequency,
    getAllPackages,
    savePackage,
    removePackage,
    payments,
    income,
    stats,
    userStats,
    userAppliedNeuro,
};
