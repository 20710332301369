import {
  FETCHING_DATA,
  SENDING_DATA,
  SET_AUTH_TOKEN,
  FETCHING_DATA_SUCCESS,
  FETCHING_DATA_FAILURE,
  SENDING_DATA_SUCCESS,
} from '../actionType';

const initialState = {
  data: [],
  dashboard: {},
  isFeching: false,
  error: false,
  token: null,
  totalItems: 0,
};

export default (state = initialState, action) => {
  const key = action.key || 'data';
  switch (action.type) {
    case FETCHING_DATA:
      return {
        ...state,
        [key]: action.value,
        token: state.token,
        isFeching: true,
        error: false,
      };
    case SET_AUTH_TOKEN:
      return {
        ...initialState,
        isFeching: false,
        token: action.token,
      };
    case SENDING_DATA:
      return {
        ...state,
        isFeching: true,
        message: null,
      };
    case FETCHING_DATA_SUCCESS:
      return {
        ...state,
        [key]: action.data,
        error: false,
        isFeching: false,
        message: null,
      };
    case FETCHING_DATA_FAILURE:
      return {
        ...state,
        [key]: [],
        isFeching: false,
        message: action.message,
        error: true,
      };
    case SENDING_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        isFeching: false,
        message: action.message,
      };
    default:
      return state;
  }
};
