import React from 'react';
import { connect, useSelector } from 'react-redux';
import Card from '../components/Card';
import '../assets/styles/_dashboard_page.scss'
import { getDashboardInfo } from '../store/actions';

function DashboardPage({loadData}) {
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const {
    totalMoney = 0,
    women = 0,
    users = 0,
    moneyOnDay = 0,
    men = 0,
    packages = 0,
    neurofrequencies = 0,
  } = useSelector(state => state.dashboard || {});

  return (
    <div className="dashboard-page">
      <Card title="Ingresos totales" amount={`$ ${totalMoney.toLocaleString()}`} />
      <Card title="Total de mujeres" amount={women.toLocaleString()} />
      <Card title="Total de usuarios" amount={users.toLocaleString()} />
      <Card title="Ingresos del día" amount={`$ ${moneyOnDay.toLocaleString()}`} />
      <Card title="Total de hombres" amount={men.toLocaleString()} />
      <Card title="Total de usuarios activos" amount={users.toLocaleString()} />
      <Card title="Ingresos por paquetes" amount={`$ ${packages.toLocaleString()}`} />
      <Card title="Ingresos por neuro frequencia" amount={`$ ${neurofrequencies.toLocaleString()}`} />
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    loadData: () => {
      dispatch(getDashboardInfo())
    },
  }
}

export default connect(null, mapDispatchToProps)(DashboardPage);
