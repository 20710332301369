import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {connect} from 'react-redux';

import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import './App.scss';
import {TOKE_NAME} from './config';
import {loginUser} from './store/actions';
import SignupPage from './Pages/SignupPage';
import { ColorRing } from 'react-loader-spinner';
const auth = {
  isAuthenticated: !!localStorage.getItem(TOKE_NAME),
  authenticate(cb) {
    auth.isAuthenticated = true;
    cb();
  },
  signout(cb) {
    auth.isAuthenticated = false;
    cb();
  }
};

function App({data}) {
  return (
    <Router>
      <Suspense fallback={
        <div className="spinner-container">
          <ColorRing
            colors={['#001A5A', '#001A5A', '#001A5A','#001A5A', '#001A5A']}
          />
        </div>
      }>
        <Switch>
          <Redirect exact from="/" to="/app/dashboard"/>
          <PrivateRoute path="/app">
            <HomePage onLogout={auth.signout}/>
          </PrivateRoute>
          <Route path="/login">
            <LoginPage onLogin={auth.authenticate}/>
          </Route>
          {/* <Route path="/signup">
            <SignupPage onSignup={auth.authenticate}/>
          </Route> */}
        </Switch>
      </Suspense>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location, props }) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: data => {
      dispatch(loginUser(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
