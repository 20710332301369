import React from 'react';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {TOKE_NAME} from '../config';
import styles from '../assets/styles/_navbar_component.module.scss';

function loadUserInfo({onExpiredToken}) {
  try {
    const userToken = localStorage.getItem(TOKE_NAME);
    const userData = jwtDecode(userToken);
    if (moment.unix(userData.exp) < moment()) {
      throw new Error('Expired token');
    }
    return userData;
  } catch (err) {
    console.log(err);
    onExpiredToken();
    return {};
  }
}

export default function Navbar(props) {
  const user = loadUserInfo(props);

  return (
    <div className={styles.navbar}>
      <div className={styles.appname}>
        <img src={require('../assets/images/logo.png')} alt="Neuro Frequencies" />
        <h3>Neuro Frequencies</h3>
      </div>
      <div className={styles.rightMenu}>
        <div className={styles.notifications}>
          <img src={require('../assets/images/bell.png')} alt="Neuro Frequencies" />
        </div>
        <div className={styles.profile}>
          {user.profilePic && (
            <img src={require('../assets/images/profile.png')} alt={user.name || '-'} />
          )}
          <h3>{user.name || '-'}</h3>
        </div>
      </div>
    </div>
  );
}
